var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "column-format gap-2",
      staticStyle: {
        width: "700px",
        "min-width": "700px",
        "max-width": "700px",
        "background-color": "var(--v-white-base)",
      },
      attrs: { id: "package-editor" },
    },
    [
      _c("div", { staticClass: "row-format gap-1 align-center pa-3" }, [
        _c("div", { staticClass: "brand-medium" }, [_vm._v("Edit package")]),
        _c(
          "div",
          { staticClass: "ml-auto" },
          [
            _c(
              "v-icon",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    return _vm.handleClose()
                  },
                },
              },
              [_vm._v("close")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "column-format gap-2 pa-3" },
        [
          _c(
            "div",
            { staticClass: "row-format gap-2" },
            [
              _c("v-text-field", {
                staticClass: "equal-size",
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Package name",
                },
                model: {
                  value: _vm.element.packageName,
                  callback: function ($$v) {
                    _vm.$set(_vm.element, "packageName", $$v)
                  },
                  expression: "element.packageName",
                },
              }),
              _c(
                "div",
                { staticClass: "row-format gap-2 equal-size" },
                [
                  _c("date-selector", {
                    staticClass: "equal-size",
                    attrs: {
                      standard: true,
                      label: "Start date (optional)",
                      date: _vm.element.startDate,
                    },
                    on: {
                      change: function ($event) {
                        _vm.element.startDate = $event
                      },
                    },
                  }),
                  _vm.element.fees.feeType === "Retainer"
                    ? _c("v-select", {
                        staticClass: "equal-size",
                        attrs: {
                          items: _vm.periods,
                          "hide-details": "",
                          "persistent-placeholder": "",
                          dense: "",
                          outlined: "",
                          "item-text": "label",
                          "item-value": "value",
                          label: "Billable periods",
                        },
                        model: {
                          value: _vm.element.fees.retainerPeriods,
                          callback: function ($$v) {
                            _vm.$set(_vm.element.fees, "retainerPeriods", $$v)
                          },
                          expression: "element.fees.retainerPeriods",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-2" },
            [
              _c("v-select", {
                staticClass: "equal-size",
                attrs: {
                  items: _vm.feeTypes,
                  "hide-details": "",
                  "persistent-placeholder": "",
                  "item-text": "label",
                  "item-value": "value",
                  dense: "",
                  outlined: "",
                  label: "Fee type",
                },
                model: {
                  value: _vm.element.fees.feeType,
                  callback: function ($$v) {
                    _vm.$set(_vm.element.fees, "feeType", $$v)
                  },
                  expression: "element.fees.feeType",
                },
              }),
              _vm.element.fees.feeType === "Retainer"
                ? _c(
                    "div",
                    { staticClass: "equal-size row-format gap-2" },
                    [
                      _c("v-select", {
                        staticClass: "equal-size",
                        attrs: {
                          items: _vm.schedule,
                          "hide-details": "",
                          "persistent-placeholder": "",
                          dense: "",
                          outlined: "",
                          label: "Recurring period",
                        },
                        model: {
                          value: _vm.element.fees.retainerSchedule,
                          callback: function ($$v) {
                            _vm.$set(_vm.element.fees, "retainerSchedule", $$v)
                          },
                          expression: "element.fees.retainerSchedule",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "equal-size",
                        attrs: {
                          items: ["Advanced", "Arrears"],
                          "hide-details": "",
                          "persistent-placeholder": "",
                          dense: "",
                          outlined: "",
                          label: "Recurring timing",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [_c("span", [_vm._v(_vm._s(item))])]
                              },
                            },
                          ],
                          null,
                          false,
                          2957485812
                        ),
                        model: {
                          value: _vm.element.fees.retainerTiming,
                          callback: function ($$v) {
                            _vm.$set(_vm.element.fees, "retainerTiming", $$v)
                          },
                          expression: "element.fees.retainerTiming",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.element.fees.feeType !== "Per Item"
            ? _c(
                "div",
                { staticClass: "row-format gap-2" },
                [
                  _c("v-text-field", {
                    staticClass: "equal-size",
                    attrs: {
                      label: "Hour estimate (min)",
                      "hide-details": "",
                      "persistent-placeholder": "",
                      dense: "",
                      outlined: "",
                      type: "number",
                      min: "0",
                      max: "10000",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.validateMinMax("min")
                      },
                    },
                    model: {
                      value: _vm.element.fees.estimateMin,
                      callback: function ($$v) {
                        _vm.$set(_vm.element.fees, "estimateMin", $$v)
                      },
                      expression: "element.fees.estimateMin",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "equal-size",
                    attrs: {
                      label: "Hour estimate (max)",
                      "hide-details": "",
                      "persistent-placeholder": "",
                      dense: "",
                      outlined: "",
                      type: "number",
                      min: "0",
                      max: "10000",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.validateMinMax("max")
                      },
                    },
                    model: {
                      value: _vm.element.fees.estimateMax,
                      callback: function ($$v) {
                        _vm.$set(_vm.element.fees, "estimateMax", $$v)
                      },
                      expression: "element.fees.estimateMax",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row-format gap-2" },
            [
              _vm.element.fees.feeType !== "Per Item"
                ? _c("v-text-field", {
                    staticClass: "equal-size",
                    attrs: {
                      prefix: _vm.currencySymbol,
                      "hide-details": "",
                      "persistent-placeholder": "",
                      dense: "",
                      outlined: "",
                      oninput: "if(this.value < 0) this.value = this.value*-1;",
                      type: "number",
                      label: "Amount",
                    },
                    model: {
                      value: _vm.element.fees.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.element.fees, "amount", $$v)
                      },
                      expression: "element.fees.amount",
                    },
                  })
                : _vm._e(),
              _vm.element.fees.feeType === "Retainer" &&
              _vm.element.fees.estimateMax
                ? _c("v-text-field", {
                    staticClass: "equal-size",
                    attrs: {
                      prefix: _vm.currencySymbol,
                      "hide-details": "",
                      "persistent-placeholder": "",
                      dense: "",
                      outlined: "",
                      oninput: "if(this.value < 0) this.value = this.value*-1;",
                      type: "number",
                      label: "Overage hourly amount",
                    },
                    model: {
                      value: _vm.element.fees.retainerOverageRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.element.fees, "retainerOverageRate", $$v)
                      },
                      expression: "element.fees.retainerOverageRate",
                    },
                  })
                : _vm._e(),
              _vm.element.fees.feeType === "Hourly"
                ? _c("v-text-field", {
                    staticClass: "equal-size",
                    attrs: {
                      suffix: "%",
                      "hide-details": "",
                      "persistent-placeholder": "",
                      dense: "",
                      outlined: "",
                      oninput:
                        "if(this.value < 0){ this.value = this.value*-1}else if(this.value > 100){this.value = 100}",
                      type: "number",
                      label: `Deposit (${_vm.depositAmount})`,
                    },
                    model: {
                      value: _vm.element.depositAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.element, "depositAmount", $$v)
                      },
                      expression: "element.depositAmount",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-checkbox", {
            attrs: {
              dense: "",
              "hide-details": "",
              "persistent-placeholder": "",
              "true-value": true,
              "false-value": false,
              label: "Taxable",
            },
            model: {
              value: _vm.element.fees.taxable,
              callback: function ($$v) {
                _vm.$set(_vm.element.fees, "taxable", $$v)
              },
              expression: "element.fees.taxable",
            },
          }),
          _vm.element.fees.feeType !== "Per Item"
            ? _c(
                "div",
                { staticClass: "row-format align-center gap-2" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.packageList,
                      "item-text": "name",
                      "item-value": "id",
                      "hide-details": "",
                      dense: "",
                      "persistent-placeholder": "",
                      outlined: "",
                      clearable: "",
                      label: "Use tasks from project template",
                    },
                    model: {
                      value: _vm.element.overrideDeliverablePackageId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.element,
                          "overrideDeliverablePackageId",
                          $$v
                        )
                      },
                      expression: "element.overrideDeliverablePackageId",
                    },
                  }),
                  _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content:
                              "Selecting this value will replace the service items specified in the agreement with tasks from the specified project template when the project is automatically created.",
                          },
                          expression:
                            "{\n\t\t\t\t\tcontent:\n\t\t\t\t\t\t'Selecting this value will replace the service items specified in the agreement with tasks from the specified project template when the project is automatically created.',\n\t\t\t\t}",
                        },
                      ],
                      staticClass: "ml-auto pointer",
                      attrs: { color: "accent" },
                    },
                    [_vm._v("help")]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.element.overrideDeliverablePackageId
            ? _c("v-select", {
                attrs: {
                  items: _vm.projectTypes,
                  "item-text": "name",
                  "item-value": "id",
                  "hide-details": "",
                  dense: "",
                  "persistent-placeholder": "",
                  outlined: "",
                  clearable: "",
                  label: "Project type",
                  placeholder: "Default project type...",
                },
                model: {
                  value: _vm.element.projectTypeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.element, "projectTypeId", $$v)
                  },
                  expression: "element.projectTypeId",
                },
              })
            : _vm._e(),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              "persistent-placeholder": "",
              label: "Button text",
            },
            model: {
              value: _vm.element.buttonText,
              callback: function ($$v) {
                _vm.$set(_vm.element, "buttonText", $$v)
              },
              expression: "element.buttonText",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "mt-2 px-2 pt-2 pb-4 row-format gap-2 centered",
          staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "delete",
              attrs: { icon: "" },
              on: { click: _vm.confirmDelete },
            },
            [_c("v-icon", [_vm._v("$delete")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { width: "160" },
              on: { click: _vm.save },
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }