var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.domain
    ? _c(
        "div",
        {
          staticClass: "fluid fill-height column-format",
          style: `${_vm.alignItems}; justify-content: center; --background:url('${_vm.domain.customBranding.loginSplash}')`,
          attrs: { id: "login-panel" },
        },
        [
          _c(
            "div",
            {
              staticClass: "pa-5",
              style: `max-width: 400px; background-color: var(--v-white-base); ${_vm.borderRadius}`,
            },
            [
              _vm.isAccountPaid && !_vm.isStarterAccount
                ? _c(
                    "v-container",
                    { staticStyle: { "max-width": "350px" } },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.isValid,
                            callback: function ($$v) {
                              _vm.isValid = $$v
                            },
                            expression: "isValid",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", align: "center" } },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      "max-height": "100px",
                                      "max-width": "100%",
                                    },
                                    attrs: { src: _vm.logo },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.view === "init"
                            ? _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", align: "left" } },
                                    [
                                      _c("div", {
                                        staticClass: "my-3",
                                        staticStyle: {
                                          "white-space": "pre-wrap",
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.domain.customBranding
                                              .loginGreeting
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", align: "left" } },
                                    [
                                      _vm.entryScreen === "start"
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                height: "40px !important",
                                              },
                                              attrs: {
                                                elevation: "0",
                                                block: "",
                                                outlined: "",
                                                color: "gray_60",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.magicLinkMode()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row-format centered",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "material-symbols-outlined",
                                                      attrs: {
                                                        size: 24,
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("magic_button")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-2 font-gray_90 font-14",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "login.use-magic-link"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.entryScreen === "start"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row-format align-center font-14 py-6",
                                            },
                                            [
                                              _c("div", {
                                                staticStyle: {
                                                  "background-color":
                                                    "var(--v-gray_50-base)",
                                                  height: "1px",
                                                  "flex-grow": "1",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-secondary px-2 brand-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "login.or-password"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticStyle: {
                                                  "background-color":
                                                    "var(--v-gray_50-base)",
                                                  height: "1px",
                                                  "flex-grow": "1",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          "persistent-placeholder": "",
                                          "hide-details": "",
                                          label: _vm.$t("login.email"),
                                          rules: _vm.emailRules,
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function ($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email",
                                        },
                                      }),
                                      _vm.entryScreen === "start"
                                        ? _c("v-text-field", {
                                            staticClass: "mt-2",
                                            attrs: {
                                              type: "password",
                                              dense: "",
                                              outlined: "",
                                              "persistent-placeholder": "",
                                              "hide-details": "",
                                              label: _vm.$t("login.password"),
                                              rules: _vm.passwordRules,
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function ($$v) {
                                                _vm.password = $$v
                                              },
                                              expression: "password",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.errorMsg
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { cols: "12", align: "left" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "var(--v-warning-base)",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.errorMsg))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-0",
                                      attrs: { cols: "12", align: "left" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticStyle: {
                                            height: "40px!important",
                                          },
                                          attrs: {
                                            elevation: "0",
                                            color: "primary",
                                            block: "",
                                            disabled: !_vm.isValid,
                                          },
                                          on: { click: _vm.handleFormSubmit },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("login.sign-in-button")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: _vm.$t("login.remember-email"),
                                          "hide-details": "",
                                          dense: "",
                                          value: true,
                                        },
                                        model: {
                                          value: _vm.rememberEmail,
                                          callback: function ($$v) {
                                            _vm.rememberEmail = $$v
                                          },
                                          expression: "rememberEmail",
                                        },
                                      }),
                                      _vm.entryScreen !== "start"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row-format align-center mt-5 font-gray_70 pointer font-14",
                                              on: {
                                                click: function ($event) {
                                                  _vm.entryScreen = "start"
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "gray_70",
                                                    size: "20",
                                                  },
                                                },
                                                [_vm._v("arrow_back")]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("login.all-options")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.view === "email-sent"
                            ? _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-8",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            large: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v("$success")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.$t("login.magic-link")) +
                                            " " +
                                            _vm._s(_vm.email) +
                                            ". " +
                                            _vm._s(_vm.$t("login.click-link"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-container",
                    { staticStyle: { "max-width": "350px" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("h1", [
                              _vm._v(
                                _vm._s(_vm.$t("login.feature-not-enabled"))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }