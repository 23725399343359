var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.deliverable.project &&
        _vm.deliverable.project.portalAccess === "Full access"
          ? "cardWrapper"
          : "",
    },
    [
      _vm.deliverable.emptyCard
        ? _c(
            "div",
            {
              staticClass: "blankCard mx-0 mt-4 pl-4 pr-0 pt-2",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("add-new-empty")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "34" } }, [_vm._v("$duplicate")]),
              _c("span", { staticClass: "font-grey" }, [
                _vm._v(_vm._s(_vm.$t("projects.add-first-deliverable"))),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "card mt-3" },
            [
              _c(
                "div",
                {
                  class: `column-format gap-2 card-wrapper ${
                    _vm.deliverable.project.portalAccess === "Full access"
                      ? "full-access"
                      : ""
                  }`,
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format" },
                    [
                      _c("div", { staticClass: "project-name ellipsis" }, [
                        _vm._v(_vm._s(_vm.deliverable.project.name)),
                      ]),
                      _vm.deliverable.invoiceId
                        ? _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content: `Invoice: ${_vm.deliverable.invoiceNumber}`,
                                  },
                                  expression:
                                    "{content:`Invoice: ${deliverable.invoiceNumber}`}",
                                },
                              ],
                              staticClass: "ml-auto",
                              attrs: { small: "", color: "success" },
                            },
                            [_vm._v("$money")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "deliverable-name row-format" }, [
                    _c("div", [_vm._v(_vm._s(_vm.deliverable.name))]),
                  ]),
                  _vm.showStatus
                    ? _c(
                        "div",
                        { staticClass: "row-format align-center font-12" },
                        [
                          _c("div", {
                            style: `width:16px; height: 16px; border-radius: 4px; background-color: ${_vm.status.hexColor}`,
                          }),
                          _vm.showStatus
                            ? _c("div", { staticClass: "ml-2" }, [
                                _vm._v(_vm._s(_vm.status.label)),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "row-format align-center gap-3" }, [
                    _vm.deliverable.assignedToList.length
                      ? _c(
                          "div",
                          { staticClass: "row-format align-center" },
                          _vm._l(
                            _vm.deliverable.assignedToList,
                            function (assignedTo, index) {
                              return _c("assigned-user", {
                                key: assignedTo,
                                style: `${
                                  index > 0 ? "margin-left: -6px" : ""
                                }; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`,
                                attrs: {
                                  "show-name": false,
                                  small: true,
                                  "assigned-to": assignedTo,
                                  "assignable-users": _vm.assignableUsers,
                                },
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row-format align-center" }, [
                      _vm.deliverable.dueDate
                        ? _c(
                            "div",
                            { staticClass: "font-12 font-grey" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mb-1", attrs: { size: "12" } },
                                [_vm._v("$clock")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DateTime.fromISO(
                                      _vm.deliverable.dueDate
                                    ).toLocaleString({
                                      month: "short",
                                      day: "numeric",
                                    })
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "row-format align-center" }, [
                    _c("div", [
                      _vm.deliverable.tasks.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "font-12 font-grey pointer task-box",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.showTasks = !_vm.showTasks
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mb-1", attrs: { size: "8" } },
                                [_vm._v("$check")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getTaskCountLabel(_vm.deliverable.tasks)
                                  ) +
                                  " "
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mb-1",
                                  attrs: { "x-small": "" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.showTasks ? "$arrowUp" : "$arrowDown"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.deliverable.tasks.length === 0
                        ? _c(
                            "div",
                            {
                              staticClass: "font-12 font-grey pointer task-box",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.readOnly ? "" : _vm.addFirstTask()
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mb-1", attrs: { size: "8" } },
                                [_vm._v("$check")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mb-1",
                                  staticStyle: { "margin-left": "2px" },
                                  attrs: { size: "12", color: "gray_80" },
                                },
                                [_vm._v("$plus")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    !_vm.deliverable.parentTaskId
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-auto row-format align-center",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.showSubtasks = !_vm.showSubtasks
                              },
                            },
                          },
                          [
                            _vm.deliverable.subtasks.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-12 row-format centered pa-1",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.deliverable.subtasks.length)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-symbols-rounded",
                                attrs: {
                                  size: "20",
                                  color: _vm.deliverable.subtasks.length
                                    ? "black"
                                    : "gray_50",
                                },
                              },
                              [_vm._v("graph_2")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _c("tasks", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTasks && _vm.deliverable.tasks.length > 0,
                    expression: "showTasks && deliverable.tasks.length > 0",
                  },
                ],
                ref: "tasks",
                attrs: { deliverable: _vm.deliverable, disabled: _vm.readOnly },
                on: {
                  empty: function ($event) {
                    _vm.showTasks = false
                  },
                  "tasks-updated": function ($event) {
                    return _vm.updateTasks()
                  },
                },
              }),
            ],
            1
          ),
      _vm.showSubtasks
        ? _c(
            "div",
            {
              staticClass: "column-format ml-4 mb-5",
              staticStyle: { "border-left": "1px solid var(--v-gray_50-base)" },
            },
            [
              _vm._l(_vm.deliverable.subtasks, function (subtask) {
                return _c(
                  "div",
                  {
                    key: subtask.id,
                    staticClass: "row-format align-center mt-2",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("edit-subtask", subtask)
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticStyle: {
                        "border-top": "1px solid var(--v-gray_50-base)",
                        height: "1px",
                        width: "18px",
                        "max-width": "18px",
                      },
                    }),
                    _c("kanban-card", {
                      staticStyle: { "flex-grow": "1" },
                      attrs: {
                        deliverable: subtask,
                        "assignable-users": _vm.assignableUsers,
                        "status-list": _vm.statusList,
                        "show-status": true,
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm.deliverable.project.portalAccess === "Full access"
                ? _c(
                    "div",
                    {
                      staticClass: "row-format align-center mt-2",
                      staticStyle: { "margin-bottom": "-9px" },
                    },
                    [
                      _c("div", {
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_50-base)",
                          height: "1px",
                          width: "18px",
                          "max-width": "18px",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pointer highlight-hover font-12 pl-1",
                          staticStyle: {
                            width: "fit-content",
                            color: "var(--v-gray_70-base)",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$emit("add-subtask")
                            },
                          },
                        },
                        [_vm._v(" + Add subtask ")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }