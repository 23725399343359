var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticClass: "py-1 ma-0 text-center",
      attrs: {
        type: _vm.alert.type,
        tile: "",
        dense: "",
        dismissible: "",
        transition: "fade-transition",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }