var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.dialog,
        "max-width": _vm.maxWidth,
        persistent: true,
        "overlay-color": _vm.overlayColor,
        "overlay-opacity": _vm.overlayOpacity,
        fullscreen: _vm.fullscreen,
      },
      on: {
        "click:outside": function ($event) {
          !_vm.persistent ? _vm.handleClose() : ""
        },
      },
    },
    [
      _c(
        "div",
        {
          class: "pa-0 ma-0" + (_vm.fullscreen ? " fullscreen" : ""),
          attrs: { id: "dialog-div" },
        },
        [
          _c(
            "v-btn",
            {
              attrs: { id: "close-x", icon: "", rounded: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleClose()
                },
              },
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { attrs: { id: "alert-div" } },
            [_c("alert", { attrs: { alert: _vm.alert } })],
            1
          ),
          _c(
            "div",
            { staticClass: "content-div pa-2" },
            [_vm._t("default", null, { setAlert: _vm.setAlert })],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }