import { render, staticRenderFns } from "./InvoiceMain.vue?vue&type=template&id=1b45761e&scoped=true"
import script from "./InvoiceMain.vue?vue&type=script&lang=js"
export * from "./InvoiceMain.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b45761e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1197256572/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b45761e')) {
      api.createRecord('1b45761e', component.options)
    } else {
      api.reload('1b45761e', component.options)
    }
    module.hot.accept("./InvoiceMain.vue?vue&type=template&id=1b45761e&scoped=true", function () {
      api.rerender('1b45761e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/invoices/InvoiceMain.vue"
export default component.exports