import { render, staticRenderFns } from "./FormV1.vue?vue&type=template&id=01f8d0e2&scoped=true"
import script from "./FormV1.vue?vue&type=script&lang=js"
export * from "./FormV1.vue?vue&type=script&lang=js"
import style0 from "./FormV1.vue?vue&type=style&index=0&id=01f8d0e2&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f8d0e2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VCol,VContainer,VRow,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1197256572/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01f8d0e2')) {
      api.createRecord('01f8d0e2', component.options)
    } else {
      api.reload('01f8d0e2', component.options)
    }
    module.hot.accept("./FormV1.vue?vue&type=template&id=01f8d0e2&scoped=true", function () {
      api.rerender('01f8d0e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/forms/v1/FormV1.vue"
export default component.exports