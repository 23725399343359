var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "solid-hr", attrs: { cols: "12", align: "left" } },
            [
              _c("div", {
                staticStyle: { "line-height": "1.8" },
                domProps: { innerHTML: _vm._s(_vm.tokenizedText) },
              }),
            ]
          ),
        ],
        1
      ),
      _vm.meet.locations.length > 1 && !_vm.isScheduled
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-n4",
                      attrs: { "hide-details": "", dense: "" },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("select-location", _vm.ourLocation)
                        },
                      },
                      model: {
                        value: _vm.ourLocation,
                        callback: function ($$v) {
                          _vm.ourLocation = $$v
                        },
                        expression: "ourLocation",
                      },
                    },
                    _vm._l(_vm.meet.locations, function (location, index) {
                      return _c("v-radio", {
                        key: index,
                        attrs: { value: location },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row-format align-center gap-2",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "material-symbols-rounded",
                                        },
                                        [_vm._v(_vm._s(_vm.getIcon(location)))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "brand-medium" },
                                        [_vm._v(_vm._s(_vm.getLabel(location)))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }