var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          style: `width: 100%; text-align: ${_vm.item.alignment}; padding-top: ${_vm.item.pt}px; padding-bottom: ${_vm.item.pb}px!important`,
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _vm.item.url
            ? _c("img", {
                style: `width:${_vm.item.scale}%; border-radius: ${_vm.item.br}px`,
                attrs: { alt: "", src: _vm.item.url },
              })
            : _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-outlined",
                      attrs: { size: "100" },
                    },
                    [_vm._v("image")]
                  ),
                ],
                1
              ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }