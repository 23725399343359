var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "detail-nav" },
    _vm._l(_vm.computedItems, function (item) {
      return _c(
        "li",
        {
          directives: [
            {
              name: "scroll-to",
              rawName: "v-scroll-to",
              value: {
                el: _vm.scrollAnchorIdPrefix + item.index,
                offset: _vm.scrollOffset,
              },
              expression:
                "{ el: scrollAnchorIdPrefix + item.index, offset: scrollOffset }",
            },
          ],
          key: item.index,
          class:
            "d-flex pt-2 pb-2 pl-1 " +
            (_vm.selectedIndex == item.index ? "selected" : ""),
          on: {
            click: function ($event) {
              return _vm.selectMe(item.index)
            },
          },
        },
        [
          item.icon
            ? _c(
                "div",
                { staticClass: "icon" },
                [
                  _c("v-icon", {
                    staticClass: "nav-icon",
                    domProps: {
                      textContent: _vm._s("$vuetify.icons." + item.icon),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div", {
            staticClass: "text",
            domProps: { innerHTML: _vm._s(item.label) },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }