var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "mx-2 py-4",
            staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" },
          },
          [
            _c(
              "div",
              { staticClass: "row-format gap-2 ml-3 pt-2" },
              [
                _vm.view === "overview"
                  ? _c("v-select", {
                      staticStyle: { "max-width": "200px" },
                      attrs: {
                        attach: true,
                        dense: "",
                        "hide-details": "",
                        items: _vm.filterOptions,
                        label: "Project status",
                      },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    })
                  : _vm._e(),
                _vm.view !== "overview"
                  ? _c(
                      "div",
                      { staticClass: "row-format gap-3" },
                      [
                        _c("v-text-field", {
                          staticStyle: {
                            "max-width": "200px",
                            "font-size": "12px!important",
                          },
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: "Filter",
                            placeholder: "Search...",
                          },
                          model: {
                            value: _vm.taskFilter,
                            callback: function ($$v) {
                              _vm.taskFilter = $$v
                            },
                            expression: "taskFilter",
                          },
                        }),
                        _c("v-autocomplete", {
                          staticStyle: {
                            "max-width": "250px",
                            "font-size": "12px!important",
                          },
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            items: _vm.projectsByType,
                            "item-text": "name",
                            "item-value": "id",
                            label: "Project",
                          },
                          model: {
                            value: _vm.projectIdFilter,
                            callback: function ($$v) {
                              _vm.projectIdFilter = $$v
                            },
                            expression: "projectIdFilter",
                          },
                        }),
                        _vm.projectTypes.length > 1
                          ? _c("v-select", {
                              staticStyle: {
                                "max-width": "200px",
                                "font-size": "12px!important",
                              },
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                items: _vm.projectTypes,
                                "item-text": "name",
                                "item-value": "id",
                                label: "Project type",
                              },
                              on: { change: _vm.saveProjectTypeId },
                              model: {
                                value: _vm.projectTypeId,
                                callback: function ($$v) {
                                  _vm.projectTypeId = $$v
                                },
                                expression: "projectTypeId",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "mx-0 pt-4 show-scrollbar",
            style: `height: calc(100vh - ${_vm.headerHeight}px)`,
            attrs: { id: "project-container" },
          },
          [
            _vm.view === "overview"
              ? _c(
                  "div",
                  { staticClass: "mx-3" },
                  _vm._l(_vm.filteredProjects, function (project) {
                    return _c("project", {
                      key: project.id,
                      staticClass: "project-wrapper",
                      attrs: {
                        project: project,
                        "status-list": _vm.getProjectType(project.projectTypeId)
                          .statusList,
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm.view === "kanban" && _vm.fullReadProjects.length > 0
              ? _c(
                  "div",
                  [
                    _c("project-kanban", {
                      attrs: {
                        "assignable-users": _vm.assignableUsers,
                        "has-full-access-projects":
                          _vm.fullAccessProjects.length > 0,
                        deliverables: _vm.filteredDeliverables,
                        "is-collaborator": true,
                        columns: _vm.activeProjectType.statusList,
                        "header-height": _vm.headerHeight,
                      },
                      on: {
                        "edit-deliverable": function ($event) {
                          return _vm.editDeliverable($event)
                        },
                        "update-kanban-props": function ($event) {
                          return _vm.updateKanbanProperties($event)
                        },
                        "add-deliverable": function ($event) {
                          return _vm.addDeliverable($event)
                        },
                        "add-subtask": function ($event) {
                          return _vm.createSubtask($event)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.view === "timeline" && _vm.fullReadProjects.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "show-scrollbar mr-3",
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c("gantt-view", {
                      attrs: {
                        "assignable-users": _vm.assignableUsers,
                        deliverables: _vm.filteredDeliverables,
                        "status-list": _vm.activeProjectType.statusList,
                        "header-height": _vm.headerHeight,
                      },
                      on: {
                        "edit-deliverable": function ($event) {
                          return _vm.editDeliverable($event)
                        },
                        "dates-updated": function ($event) {
                          return _vm.datesUpdated($event)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.view === "calendar" && _vm.fullReadProjects.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "show-scrollbar mr-3",
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c("project-calendar", {
                      attrs: {
                        projects: _vm.filteredProjects,
                        deliverables: _vm.filteredDeliverables,
                        "status-list": _vm.activeProjectType.statusList,
                      },
                      on: {
                        "edit-deliverable": function ($event) {
                          return _vm.editDeliverable($event)
                        },
                        "update-due-date": function ($event) {
                          return _vm.datesUpdated($event)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }