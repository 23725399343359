import { render, staticRenderFns } from "./AssignedUser.vue?vue&type=template&id=6b5f332e&scoped=true"
import script from "./AssignedUser.vue?vue&type=script&lang=js"
export * from "./AssignedUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5f332e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
installComponents(component, {VAvatar})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1197256572/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b5f332e')) {
      api.createRecord('6b5f332e', component.options)
    } else {
      api.reload('6b5f332e', component.options)
    }
    module.hot.accept("./AssignedUser.vue?vue&type=template&id=6b5f332e&scoped=true", function () {
      api.rerender('6b5f332e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AssignedUser.vue"
export default component.exports