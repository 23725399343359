var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mr-3" }, [
    _c("table", [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.dateAxis, function (month, propertyName) {
            return _c(
              "th",
              {
                key: propertyName,
                staticClass: "text-center",
                attrs: { colspan: month.length },
              },
              [
                _c("span", { staticClass: "font-gray_80 brand-medium" }, [
                  _vm._v(
                    _vm._s(
                      _vm.DateTime.fromISO(propertyName).toLocaleString({
                        month: "long",
                        year: "numeric",
                      })
                    )
                  ),
                ]),
              ]
            )
          }),
          0
        ),
        _c(
          "tr",
          { attrs: { id: "gantt-days" } },
          [
            _vm._l(_vm.dateAxis, function (month) {
              return _vm._l(month, function (date) {
                return _c(
                  "th",
                  {
                    key: date.toISODate(),
                    staticClass: "text-center",
                    staticStyle: {
                      "min-width": "35px",
                      "max-width": "35px",
                      position: "relative",
                    },
                    attrs: {
                      "data-id": date.toISODate(),
                      id: `gantt-column-${date.toISODate()}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          (date.toISODate() === _vm.DateTime.now().toISODate()
                            ? "today"
                            : "") + " row-format centered",
                        staticStyle: { width: "24px", height: "24px" },
                      },
                      [_c("div", [_vm._v(_vm._s(date.toFormat("d")))])]
                    ),
                    date.toISODate() === _vm.DateTime.now().toISODate()
                      ? _c("div", { staticClass: "today-bar" })
                      : _vm._e(),
                  ]
                )
              })
            }),
          ],
          2
        ),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.calculatedRows, function (row) {
          return _c(
            "tr",
            {
              key: row.id,
              staticStyle: { "min-height": "60px" },
              on: { dragover: _vm.handleDragOver },
            },
            _vm._l(row.blocks, function (block) {
              return _c(
                "td",
                {
                  key: block.id,
                  staticStyle: {
                    "max-width": "35px",
                    "border-left": "none",
                    "border-right": "none",
                    "min-height": "30px",
                  },
                  attrs: { colspan: block.days },
                },
                [
                  block.type === "deliverable"
                    ? _c(
                        "vue-resizable",
                        {
                          attrs: {
                            "data-id": row.id,
                            width: "auto",
                            "max-height": 42,
                            "min-height": 42,
                          },
                          on: { "resize:end": _vm.handleEndResize },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row-format align-center deliverable gantt-deliverable pl-2 pointer",
                              staticStyle: { height: "100%" },
                              attrs: { "data-id": row.id, draggable: "true" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("edit-deliverable", row)
                                },
                                dragstart: _vm.handleDragStart,
                                dragend: _vm.handleDragEnd,
                                drop: _vm.handleDrop,
                                dragenter: _vm.handleDragEnter,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-left",
                                  staticStyle: { "white-space": "nowrap" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-14 brand-medium" },
                                    [_vm._v(_vm._s(row.name))]
                                  ),
                                  _c("div", { staticClass: "font-12" }, [
                                    _vm._v(_vm._s(row.project.name)),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "ml-2 row-format align-center" },
                                _vm._l(
                                  row.assignedToList,
                                  function (assignedTo, index) {
                                    return _c("assigned-user", {
                                      key: assignedTo,
                                      style: `${
                                        index > 0 ? "margin-left: -6px" : ""
                                      }; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`,
                                      attrs: {
                                        "show-name": false,
                                        small: true,
                                        "assigned-to": assignedTo,
                                        "assignable-users": _vm.assignableUsers,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              row.tasks.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-2 row-format align-center",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { size: "8" },
                                        },
                                        [_vm._v("$check")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "font-gray_80 font-12" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getTaskCountLabel(row.tasks)
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "deliverableStatus row-format align-center ml-2",
                                  style: `--status-color:${row.status.hexColor}`,
                                },
                                [
                                  _c("div", { staticClass: "statusBox" }),
                                  _c("div", [_vm._v(_vm._s(row.status.label))]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "ml-4 row-format align-center" },
                                [
                                  row.subtasks.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-12 row-format centered pa-1",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(row.subtasks.length)),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: {
                                        size: "20",
                                        color: row.subtasks.length
                                          ? "black"
                                          : "gray_50",
                                      },
                                    },
                                    [_vm._v("graph_2")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }