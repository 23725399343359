var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper", style: _vm.heightCssVars }, [
    _c(
      "div",
      {
        class: _vm.option1css,
        style: _vm.selectedColorsCss,
        on: {
          click: function ($event) {
            return _vm.optionSelected(_vm.option1)
          },
        },
      },
      [_vm._v(_vm._s(_vm.option1))]
    ),
    _c(
      "div",
      {
        class: _vm.option2css,
        style: _vm.selectedColorsCss,
        on: {
          click: function ($event) {
            return _vm.optionSelected(_vm.option2)
          },
        },
      },
      [_vm._v(_vm._s(_vm.option2))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }